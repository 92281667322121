
import http from "../../../../../common/services/http";
import { defineComponent, onBeforeMount, onMounted, reactive, ref } from "vue";
import NgongVegBanner from "@/components/NgongVegBanner.vue";

export default defineComponent({
  components: { NgongVegBanner },
  setup(props) {
    const headers = {
      name: "Customer"
      // phone: "Phone Number",
    };

    const currencies = ref({
      euro: "Euro",
      pound: "Sterling Pound",
      shilling: "Kenyan Shilling",
      dollar: "US Dollar"
    });

    const error = ref("");
    const has_error = ref(false);

    const formats = ref({ Pallet: "Pallet", AKE: "AKE", Loose: "Loose" });

    const customers = ref({
      data: [],
      first_page: 1,
      last_page: 1
    });

    const customer = reactive({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      role: { id: "customer", title: "Customer" },
      roles: [],
      contact: {
        name: "",
        email: "",
        phone: "",
        currency: "pound",
        address_1: "",
        shipping_location: "",
        freight_cost: "",
        net_to_gross_percentage: "",
        bundles: 0,
        format: "Pallet",
        agent_id: 0,
        reps: [{ name: "", email: "" }]
      }
    });

    const buyer = ref({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      role: { id: "customer", title: "Customer" },
      roles: [],
      contact: {
        name: "",
        email: "",
        phone: "",
        currency: "pound",
        address_1: "",
        shipping_location: "",
        freight_cost: "",
        net_to_gross_percentage: "",
        bundles: 0,
        format: "Pallet",
        agent_id: 0,
        reps: [{ name: "", email: "" }]
      }
    });

    const agents = ref([]);
    const selected = ref([]);

    const roles = ref([]);

    const search = ref("");

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    function fetchCustomers(page = 1) {
      processing.value = true;
      http
        .get(`/api/users?page=${page}`)
        .then(res => {
          customers.value = res.data;
        })
        .finally(() => {
          processing.value = false;
        });
    }

    function fetchCustomerByRole(role: any) {
      processing.value = true;
      http.get(`/api/users?role=${role}`).then(res => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    function changeUserType(event: any) {
      if (event.target.value === "all") {
        fetchCustomers();
      } else {
        fetchCustomerByRole(event.target.value);
      }
    }

    function fetchRoles(page = 1) {
      processing.value = true;

      http.get(`/api/roles`).then(res => {
        roles.value = res.data;
        processing.value = false;
      });
    }

    function fetchAgents() {
      processing.value = true;
      http.get(`/api/users?role=agent&fetch=all`).then(res => {
        agents.value = res.data;
        processing.value = false;
      });
    }

    function findAgent(id: any) {
      return agents.value.find((a: any) => a.id == id);
    }

    function searchCustomers(e: any) {
      processing.value = true;
      http.get(`/api/users?s=${search.value}`).then(res => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    function createCustomer() {
      has_error.value = false;
      error.value = "";
      if (customer.phone === "") {
        has_error.value = true;
        error.value = "Phone cannot be blank";
      } else if (customer.email === "") {
        has_error.value = true;
        error.value = "Email cannot be blank";
      } else {
        http
          .post("/api/users", customer)
          .then(res => {
            if (res.data.success) {
              created.value = true;

              customer.first_name = "";
              customer.last_name = "";
              customer.email = "";
              customer.phone = "";
              customer.role = { id: "customer", title: "Customer" };
              customer.roles = [];
              customer.contact = {
                name: "",
                email: "",
                phone: "",
                currency: "pound",
                address_1: "",
                shipping_location: "",
                freight_cost: "",
                net_to_gross_percentage: "",
                bundles: 0,
                format: "Pallet",
                agent_id: 0,
                reps: [{ name: "", email: "" }]
              };
              has_error.value = false;
              error.value = "";
              document.getElementById("hideNewForm")?.click();

              fetchCustomers();
            }
          })
          .catch(function(e) {
            if (e.response.data.errors["email"]) {
              has_error.value = true;
              error.value = "The email has already been taken";
            }
            if (e.response.data.errors["phone"]) {
              has_error.value = true;
              error.value = "The phone has already been taken";
            }
          });
      }
    }

    function setCustomer(u: any) {
      if (u.contact == null) {
        u.contact = {
          name: "",
          email: "",
          phone: "",
          currency: "pound",
          address_1: "",
          shipping_location: "",
          freight_cost: "",
          net_to_gross_percentage: "",
          bundles: 0,
          format: "Pallet",
          agent_id: 0,
          reps: [{ name: "", email: "" }]
        };
      } else if (u.contact.reps == null) {
        u.contact.reps = [{ name: "", email: "" }];
      }

      buyer.value = u;
    }

    function getBuyerRoles(roles: any) {
      if (roles) {
        buyer.value.roles = roles.map((a: { id: any }) => a.id);
      }
    }

    function updateCustomer(c: any) {
      c._method = "PUT";
      http
        .post(`/api/users/${c.id}`, c)
        .then(res => {
          document.getElementById("hideEditForm")?.click();
          if (res.data.success) {
            updated.value = true;
          }
        })
        .finally(() => {
          fetchCustomers();
        });
    }

    function addRep(u: any) {
      u.push({ name: "", email: "" });
    }

    function delRep(u: any, i: any) {
      u.splice(i, 1);
    }

    function deleteCustomer(id: any) {
      http.post(`/api/users/${id}`, { _method: "DELETE" }).then(res => {
        fetchCustomers();
      });
    }

    onBeforeMount(() => {
      fetchAgents();
    });

    onMounted(() => {
      fetchCustomers();
      fetchRoles();
    });

    return {
      headers,
      fetchCustomers,
      processing,
      agents,
      customers,
      roles,
      createCustomer,
      created,
      customer,
      updateCustomer,
      updated,
      deleteCustomer,
      search,
      searchCustomers,
      currencies,
      selected,
      findAgent,
      formats,
      buyer,
      setCustomer,
      addRep,
      delRep,
      getBuyerRoles,
      has_error,
      error,
      changeUserType
    };
  }
});
